<template>
  <b-card>
    <b-row>
      <b-col cols="12" md="9" lg="9">
        <table class="w-100">
          <tr class="border-bottom">
            <th class="pb-50"><span class="font-weight-bold">İsim</span></th>
            <td class="pb-50">{{ dataInfo.name }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Kategori</span></th>
            <td class="py-50">{{ dataInfo.assetCategoryName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Marka</span></th>
            <td class="py-50">{{ dataInfo.assetBrandName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Model</span></th>
            <td class="py-50">{{ dataInfo.modelName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Seri Numarası</span></th>
            <td class="py-50">{{ dataInfo.serialNumber }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Sorumlu Organizasyon</span></th>
            <td class="py-50">{{ dataInfo.organizationName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">İşyeri</span></th>
            <td class="py-50">{{ dataInfo.workplaceName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Garanti Süresi (Yıl)</span></th>
            <td class="py-50">{{ dataInfo.warrantyPeriodValue ? dataInfo.warrantyPeriodValue : '-' }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Açıklama</span></th>
            <td class="py-50">{{ dataInfo.description }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Durum</span></th>
            <td class="py-50">{{ dataInfo.assetStatusName }}</td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Çalışan</span></th>
            <td class="py-50">
              <div class="d-flex justify-content-start align-items-center" v-if="dataInfo.embezzledUserId">
                <b-avatar class="mr-1" :src="getApiFile(dataInfo.embezzledUserPicture)" :text="avatarText(dataInfo.embezzledUserName)" :variant="`light-primary`" size="50"/>
                <div class="profile-user-info">
                  <h6 class="mb-0">{{ dataInfo.embezzledUserName }}</h6>
                  <div class="d-flex justify-content-start align-items-start flex-column">
                    <small class="text-muted">{{ dataInfo.embezzledUserPositionName }}</small>
                  </div>
                </div>
              </div>
              <span v-if="!dataInfo.embezzledUserId">-</span>
            </td>
          </tr>
        </table>
      </b-col>
      <b-col cols="12" md="3" lg="3" class="d-flex flex-wrap justify-content-center align-content-center">
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-avatar
                rounded button
                size="200px"
                ref="previewEl"
                :src="getApiFile(dataInfo.imagePath)"
                variant="primary">
              <feather-icon icon="LayersIcon" size="150" v-if="dataInfo.imagePath === null"/>
            </b-avatar>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BTabs, BTab, BFormTextarea, BAvatar, BFormCheckbox, BButton
} from 'bootstrap-vue'
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {toastMessage} from "@core/utils/utils";
import router from '@/router'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import {avatarText, getApiFile} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/asset/asset/store"
import {DateTimeColumnTable} from "@/components/Table";

export default {
  components: {
    BButton,
    DateTimeColumnTable,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    BFormTextarea,
    BAvatar,

    ActionButtons,
    Overlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({})

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/previewItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'asset-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    return {
      busy,
      dataInfo,

      getApiFile,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>